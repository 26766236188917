<template>
  <div>
      <wanalyzer-stats-view></wanalyzer-stats-view>
  </div>
</template>

<script>
import {
  default as WanalyzerStatsView
} from '@/components/widgets/WanalyzerStatsView';
import { mapGetters } from 'vuex';
export default {
  name: 'user_statistics_view_products',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    WanalyzerStatsView,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
  },
  watch: {}
}

</script>

